import React, { Suspense, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { BlogDesktop } from "../components/Blog/BlogDesktop";
import { BlogMobile } from "../components/Blog/BlogMobile";

export type Blog = { title: string; imgSrc: string; content: string };

export function BlogPage() {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [blogs, setBlogs] = useState([] as Blog[]);
  return (
    <Suspense>
      <Helmet>
        <title>{t("helmet.home_title")}</title>
        <meta
          name="description"
          content={
            t("helmet.home_desc") || "Propaganda is an online funny board game"
          }
        ></meta>
      </Helmet>
      <div className="w-full h-full overflow-hidden">
        <BlogMobile blogs={blogs} />
        <BlogDesktop blogs={blogs} />
      </div>
    </Suspense>
  );
}
