import React, { Suspense, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import cover from "../../assets/cover-mob.svg";
import insta from "../../assets/blog-instagram.svg";
import tik from "../../assets/blog-tiktok..svg";
import twit from "../../assets/blog-twitter..svg";
import copyright from "../../assets/copyright.svg";
import search from "../../assets/search.svg";
import burg from "../../assets/burg.svg";
import { Card } from "./Card";
import { Blog } from "../../pages/Blog";
import { Modal } from "./Modal";
import { content } from "../../utils/constants";

export function BlogMobile(props: { blogs: Blog[] }) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(null! as Blog);
  const b: Blog = {
    title: "افكار العاب جماعية استعد لأوقات مليئة بالمرح!",
    content: content,
    imgSrc:
      "https://miro.medium.com/v2/resize:fit:720/format:webp/1*-Y_zkzI3teGEI6-Jah_ljQ.jpeg",
  };
  return (
    <Suspense>
      <Helmet>
        <title>{t("helmet.home_title")}</title>
        <meta
          name="description"
          content={
            t("helmet.home_desc") || "Propaganda is an online funny board game"
          }
        ></meta>
      </Helmet>
      <div className="relative w-full h-full min-h-screen bg-gray-blog flex flex-col phone:visible web:hidden">
        {selected && (
          <Modal
            blog={selected}
            onSelect={setSelected}
            back={t("blog.title") || "العودة"}
          ></Modal>
        )}
        <div className="absolute top-0 w-full flex flex-row">
          <div className="w-[75%] pt-4 px-8 flex flex-row items-center justify-between ">
            <img src={burg} alt="menu" className="w-6"></img>
            <h3 className="text-white font-Inter font-bold text-xl">
              THE FUNNY1S
            </h3>
          </div>
        </div>
        <img
          src={cover}
          className="w-full"
          alt="family having fun playing"
        ></img>
        <div className="bg-gray-blog pb-12 h-full grow">
          <div className="mt-[-200px] relative w-full h-full flex flex-col bg-transparent z-20 items-center justify-start">
            <h1
              className="text-3xl text-center text-white font-bold font-Inter"
              style={{ textShadow: "8px 2px 2px rgba(0,0,0,0.6)" }}
            >
              {t("blog.title")}
            </h1>
            <div className=" w-full flex flex-row gap-8 mt-4 px-8">
              <button className="w-[25%] h-10 bg-blue-blog p-2 rounded-md border-2 font-Inter font-bold border-white shadow-lg">
                {t("blog.search")}
              </button>
              <div className="relative w-[75%]">
                {" "}
                <input
                  type="text"
                  className="w-full h-10 bg-white border-2 border-black rounded-md font-Inter shadow-lg placeholder:text-rose-500"
                ></input>
                <img
                  src={search}
                  alt="search"
                  className="absolute right-2 top-3 w-4"
                ></img>
              </div>
            </div>
            <div className="w-full grid grid-cols-2 gap-8 mt-8 px-8">
              <Card onSelect={setSelected} blog={b} />
            </div>
            <button className="w-[90%] bg-transparent text-white rounded-md text-md border-2 font-Inter border-white mt-10 p-1">
              {t("blog.load_more")}
            </button>
            <div className="flex flex-row w-full items-center justify-center px-2 pt-12">
              <div
                className="grow mx-2"
                style={{
                  height: "2px",
                  borderBottom: "1px solid rgba(255,255,255,.55)",
                }}
              ></div>
              <h3 className="text-white font-Inter text-xl">
                {t("blog.hashtags")}
              </h3>
              <div
                className="grow mx-2"
                style={{
                  height: "2px",
                  borderBottom: "1px solid rgba(255,255,255,.55)",
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="w-full bg-black flex flex-col items-center gap-2 py-4 ">
          <h3 className="text-white font-Inter font-bold text-xl p-4">
            THE FUNNY1S
          </h3>
          <div className="flex flex-row gap-24 p-4">
            <img className="w-4" src={twit} alt="twitter"></img>
            <img className="w-4" src={tik} alt="tiktok"></img>
            <img className="w-4" src={insta} alt="instagram"></img>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              borderBottom: "1px solid rgba(255,255,255)",
            }}
          ></div>
          <h2 className="text-white text-md">الشروط و الاحكام</h2>
          <img src={copyright} alt="Copyright reserved"></img>
        </div>
      </div>
    </Suspense>
  );
}
