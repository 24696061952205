import React, { Suspense, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import propaRed from "../../assets/porpa.svg";
import propaRev from "../../assets/propa-reversed.svg";
import { useAddBillingInfoMutation } from "../../store/Apis/UserApi";
import { setAuthenticated } from "../../store/slices/UserSlice";
import { RootState } from "../../store/store";
import "./SignForm.css";
import countriesAR from "../../utils/countriesAR.json";
import countriesEN from "../../utils/countriesEN.json";
import { SocketContext } from "../../context/socket";

export function BillingInfo(props: { next: any }) {
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState({
    country: "sa",
    state: "",
    city: "",
    street: "",
    postcode: "",
  });
  const socket = useContext(SocketContext);
  const [errors, setErrors] = useState({});
  const [countries, setCountries] = useState(countriesAR);
  const [margin, setMargin] = useState("ml");
  const [register] = useAddBillingInfoMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const authenticated = useSelector(
    (state: RootState) => state.user.authenticated
  );
  //const user = useSelector((state: RootState) => state.user.user)
  const hasPropaganda = useSelector(
    (state: RootState) => state.user.hasPropaganda
  );

  useEffect(() => {
    if (authenticated && hasPropaganda) 
      navigate("/");
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (i18n.language !== "ar") {
      setCountries(countriesEN);
      setMargin("mr");
      setFormData({ ...formData});
    }
  });

  const createRoom = () => {
    socket.emit("createLobby", { email: user.email , familyFriendly: true});
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      // Form can be submitted
      register(formData)
        .unwrap()
        .then((data) => {
          console.log(data)
          dispatch(setAuthenticated(data));
          props.next();
        })
        .catch((err) => {
          console.log(err);
          if (err.data.error_code)
            setErrors({
              error: t("errors." + err.data.error_code) || err.data.error,
            });
          else setErrors({ error: err.data.error });
        });
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = () => {
    const validationErrors: any = {};
    /*for (const [name, value] of Object.entries(formData)) {
      const err = validateInput(name, value);
      if (err) validationErrors[name] = err;
    }
    if (!termsAgreed)
      validationErrors["terms"] = "Please agree to the terms and conditions.";*/
    return validationErrors;
  };

  const handleChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    //setErrors({ ...errors, [event.target.name]: validateInput(event.target.name, event.target.value) });
  };

  return (
    <Suspense>

      <div className="w-full h-full flex flex-row">
        <div className="w-[20%] h-full flex items-end justify-end">
          <img alt="retro" className="h-[55%] mb-[20%]" src={propaRed}></img>
        </div>
        <form
          onSubmit={handleSubmit}
          className="w-[60%] h-full flex flex-col bg-orange items-end justify-center phone:hidden web:visible"
        >
          <div className="w-full flex flex-col items-center justify-center gap-4">
            <h2 className="font-Inter font-bold text-3xl">{t("signin.billing")}</h2>
            <div className="w-[80%] flex flex-row gap-4">
              <select
                className="w-[33%]  h-12 bg-landing-yellow border-2 border-red font-Inter shadow-lg placeholder:text-rose-500 px-4"
                name="country"
                value={formData.country}
                onChange={handleChange}
              >
                {countries.map((country) => {
                  return (
                    <option
                      key={country.name}
                      className="text-black bg-transparent"
                      value={country.alpha2}
                    >
                      {country.name}
                    </option>
                  );
                })}
              </select>
              <input
                className="w-[33%] h-12 bg-landing-yellow border-2 border-red font-Inter shadow-lg placeholder:text-rose-500 px-4"
                placeholder={t("signin.state") || "State"}
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
                min="3"
                required
              ></input>
              <input
                className="w-[33%] h-12 bg-landing-yellow border-2 border-red font-Inter shadow-lg placeholder:text-rose-500 px-4"
                placeholder={t("signin.city") || "City"}
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                min="3"
                required
              ></input>
            </div>
            <div className="w-[80%] flex flex-row gap-4">
              <input
                className="w-[70%] h-12 bg-landing-yellow border-2 border-red font-Inter shadow-lg placeholder:text-rose-500 px-4"
                placeholder={t("signin.street") || "Street"}
                type="text"
                name="street"
                value={formData.street}
                onChange={handleChange}
                min="3"
                required
              ></input>
              <input
                className="w-[30%] h-12 bg-landing-yellow border-2 border-red font-Inter shadow-lg placeholder:text-rose-500 px-4"
                placeholder={t("signin.postcode") || "Postcode"}
                type="text"
                name="postcode"
                value={formData.postcode}
                onChange={handleChange}
                min="3"
                required
              ></input>
            </div>
          </div>
          {Object.entries(errors).length > 0 && (
            <ul className="w-full flex flex-col items-center justify-center">
              {Object.entries(errors).map(([key, value], index) => {
                if (value)
                  return (
                    <li
                      key={index}
                      className="web:text-red phone:text-yellow-400 text-sm font-bold w-[80%] "
                    >
                      {value.toString()}
                    </li>
                  );
                else return null;
              })}
            </ul>
          )}
          <div className="flex flex-row w-[90%] justify-between">
          <button
            className={`w-[30%] h-12 bg-red p-2 mt-4 border-2 font-Inter font-bold border-black shadow-lg`}
            onClick={() => createRoom()}
          >
            {t("herobanner.free_trial")||"Start trial game"}
          </button>
          <button
            className={`w-[30%] ${margin}-[10%] h-12 bg-light-blue p-2 mt-4 border-2 font-Inter font-bold border-black shadow-lg`}
            type="submit"
          >
            {t("signin.subscribe")}
          </button>
          </div>
        </form>
        <form
          onSubmit={handleSubmit}
          className="flex overflow-y-auto pb-8 flex-col justify-between w-full h-full bg-red items-center web:hidden phone:visible"
        >
          <div className="w-full flex flex-col items-center justify-center">
            <select
              className="w-[100%] h-12 bg-landing-yellow border-2 border-red font-Inter shadow-lg shadow-black placeholder:text-rose-500 px-6 mt-4"
              name="country"
              value={formData.country}
              onChange={handleChange}
            >
              {countries.map((country) => {
                return (
                  <option
                    key={country.name}
                    className="text-black bg-transparent"
                    value={country.alpha2}
                  >
                    {country.name}
                  </option>
                );
              })}
            </select>
            <input
              className="w-[100%] h-12 bg-landing-yellow border-2 border-red font-Inter shadow-lg shadow-black placeholder:text-rose-500 px-6 mt-4"
              placeholder={t("signin.state") || "State"}
              type="text"
              name="state"
              value={formData.state}
              onChange={handleChange}
            ></input>
            <input
              className="w-[100%] h-12 bg-landing-yellow border-2 border-red font-Inter shadow-lg shadow-black placeholder:text-rose-500 px-6 mt-4"
              placeholder={t("signin.city") || "City"}
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
            ></input>
            <input
              className="w-[100%] h-12 bg-landing-yellow border-2 border-red font-Inter shadow-lg shadow-black placeholder:text-rose-500 px-6 mt-4"
              placeholder={t("signin.street") || "Street"}
              type="text"
              name="street"
              value={formData.street}
              onChange={handleChange}
            ></input>
            <input
              className="w-[100%] h-12 bg-landing-yellow border-2 border-red font-Inter shadow-lg shadow-black placeholder:text-rose-500 px-6 mt-4"
              placeholder={t("signin.postcode") || "Postcode"}
              type="text"
              name="postcode"
              value={formData.postcode}
              onChange={handleChange}
            ></input>
          </div>
          {Object.entries(errors).length > 0 && (
            <ul className="w-full flex flex-col items-start justify-center">
              {Object.entries(errors).map(([key, value], index) => {
                if (value)
                  return (
                    <li
                      key={index}
                      className="web:text-red phone:text-yellow-400 text-sm w-[80%] "
                    >
                      {value.toString()}
                    </li>
                  );
                else return null;
              })}
            </ul>
          )}
          <input
            className="w-[100%] h-12 bg-light-blue p-2 mt-4 border-2 font-Inter font-bold border-black shadow-lg z-50"
            type="submit"
            value={t("signin.subscribe") || "Sign in"}
          />
           <button
            className="w-[100%] h-12 bg-orange p-2 mt-2 border-2 font-Inter font-bold border-black shadow-lg z-50"
            onClick={() => createRoom()}
          >
            {t("herobanner.free_trial") || "Start trial game"}
            </button>
        </form>
        <div className="w-[20%] h-full flex items-end justify-start">
          <img
            alt="reversed retro"
            className="h-[55%] mb-[20%]"
            src={propaRev}
          ></img>
        </div>
      </div>
    </Suspense>
  );
}
