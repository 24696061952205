import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import money from "../../assets/money.svg";
import { useNavigate } from "react-router-dom";

export function PurshaseBanner() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Suspense>
      <div className="flex web:flex-row flex-col w-full web:h-[60vh] bg-cover bg-landing-yellow content-center mt-20">
        <div className="grow web:mx-[10%] mx-4 flex flex-col web:order-1 order-2 basis-1/3 justify-center">
          <h5 className="web:w-[100%] w-[60%] web:mt-0 mt-8  web:text-3xl text-xl font-Inter text-black font-light">
            {t("purshase.title")}
          </h5>
          <button
            className="shrink mt-4 web:w-44 w-[95%] p-4 text-xl  bg-orange shadow-md shadow-slate-700 border-2 border-black"
            onClick={() => navigate("/purshase")}
          >
            {" "}
            {t("purshase.button")}
          </button>
        </div>
        <div className="grow flex basis-2/3 order-1 web:order-2  justify-center">
          <img alt="money" src={money}></img>
        </div>
      </div>
    </Suspense>
  );
}
