import React, { Suspense, useContext, useEffect } from "react";
import { Navbar } from "./Navbar";
import propaganda from "../../assets/propaganda.png";
import propagandaEng from "../../assets/propaganda-eng.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { SocketContext } from "../../context/socket";
import { useNavigate } from "react-router-dom";
import {
  setCurrentPage,
  setRoomCode,
} from "../../store/slices/MainScreenSlice";
import Cookies from "js-cookie";
import TagManager from "react-gtm-module";

export function HeroBanner() {
  const { t } = useTranslation();
  //const authenticated = useSelector((state: RootState) => state.user.authenticated)
  const user = useSelector((state: RootState) => state.user.user);
  const familyFriendly = useSelector((state: RootState) => state.user.familyFriendly);
  const hasPropaganda = useSelector(
    (state: RootState) => state.user.hasPropaganda
  );
  const authenticated = useSelector(
    (state: RootState) => state.user.authenticated
  );
  const socket = useContext(SocketContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = Cookies.get("language") ? Cookies.get("language") : "ar";

  useEffect(() => {
    if (socket) {
      socket.off("created");
      socket.off("ERROR");
      socket.on("created", (data) => {
        dispatch(setRoomCode(data));
        dispatch(setCurrentPage("logos"));
        navigate("/game");
      });
      socket.on("ERROR", (data) => {
        console.log("ERROR", data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const createRoom = () => {
    socket.emit("createLobby", { email: user.email , familyFriendly: familyFriendly});
  };
  const joinGame = () => {
    navigate("/join");
  };

  const purshase = () => {
    TagManager.dataLayer({ dataLayer: { event: "purchase" } });
    navigate("/purshase");
  };
  //{t('herobanner.about')}
  return (
    <Suspense>
      <div className="relative w-full h-screen max-w-screen bg-cover web:bg-hero-banner bg-hero-banner-mobile bg-top m-0">
        <div className="absolute top-0">
          <Navbar></Navbar>
        </div>
        <div className="web:w-[40%] absolute web:right-28 web:bottom-28 bottom-0 flex flex-col">
          <img
            alt="propaganda ar img"
            className={`w-[100%] self-center ${
              language === "ar" ? "" : "mb-8"
            } `}
            src={language === "ar" ? propaganda : propagandaEng}
          ></img>
          <p className="web:w-[90%] pr-4 web:text-landing-yellow web:text-2xl text-lg font-Inter">
            {t("herobanner.about")}
          </p>
          <div className="flex web:flex-row flex-col web:justify-between web:mb-0 mb-4 web:w-[90%] pt-4 pr-4">
            {!hasPropaganda && (
              <button
                className="shrink  web:p-4 web:mx-0 web:px-12 py-4 mx-4 web:mb-0 mb-4  bg-orange web:shadow-md shadow-sm shadow-slate-700 border-2 border-black text-Inter font-bold"
                onClick={() => purshase()}
              >
                {t("herobanner.buy")}
              </button>
            )}
            {!hasPropaganda&&authenticated&&
            <button
            className="shrink  web:p-4 web:mx-2 web:px-12 py-4 mx-4 web:mb-0 mb-4  bg-orange web:shadow-md shadow-sm shadow-slate-700 border-2 border-black text-Inter font-bold"
            onClick={() => createRoom()}
          >
            {t("herobanner.free_trial")}
          </button>
            }
            <button
              className="shrink   web:p-4 web:mx-0 web:px-16 py-4 mx-4 bg-orange web:shadow-md shadow-sm shadow-slate-700 border-2 border-black text-Inter font-bold"
              onClick={() => joinGame()}
            >
              {" "}
              {t("herobanner.join")}
            </button>
            {hasPropaganda && (
              <button
                className="shrink   web:p-4 web:mx-0 web:px-16 py-4 mx-4 bg-orange web:shadow-md shadow-sm shadow-slate-700 border-2 border-black text-Inter font-bold"
                onClick={() => createRoom()}
              >
                {t("herobanner.start")}
              </button>
            )}
          </div>
        </div>
      </div>
    </Suspense>
  );
}
