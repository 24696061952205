export const bgVariants = [
  {
    id: 0,
    bg: "1.svg",
    ProgressBarColor: "bg-[#F1B359]",
    ProgressBarTextColor: "text-[#FC4148]",
  },
  {
    id: 1,
    bg: "2.svg",
    ProgressBarColor: "bg-[#FC4148]",
    ProgressBarTextColor: "text-[#2C3D4F]",
  },
  {
    id: 2,
    bg: "3.svg",
    ProgressBarColor: "bg-[#6DBCDB]",
    ProgressBarTextColor: "text-[#FC4148]",
  },
  {
    id: 3,
    bg: "4.svg",
    ProgressBarColor: "bg-[#F1B359]",
    ProgressBarTextColor: "text-[#FC4148]",
  },
  {
    id: 4,
    bg: "5.svg",
    ProgressBarColor: "bg-[#2C3D4F]",
    ProgressBarTextColor: "text-[#F1B359]",
  },
  {
    id: 5,
    bg: "6.svg",
    ProgressBarColor: "bg-[#FC4148]",
    ProgressBarTextColor: "text-[#2C3D4F]",
  },
  {
    id: 6,
    bg: "7.svg",
    ProgressBarColor: "bg-[#2C3D4F]",
    ProgressBarTextColor: "text-[#F1B359]",
  },
];

export const playersVariants = [
  { color: "#F159E2", voiceOver: "cork_plop.mp3" },
  { color: "#00FF66", voiceOver: "cow_mow.mp3" },
  { color: "#00EAC0", voiceOver: "duck_quack.mp3" },
  { color: "#F1B359", voiceOver: "duck_toy.mp3" },
  { color: "#9C59F1", voiceOver: "fart.mp3" },
  { color: "#FC4148", voiceOver: "gun_shot.mp3" },
  { color: "#2C3D4F", voiceOver: "metal_ding.mp3" },
  { color: "#6DBCDB", voiceOver: "reload_gun.mp3" },
  { color: "#D9ACF5", voiceOver: "short_kiss.mp3" },
  { color: "#00F5FF", voiceOver: "" },
];

export const content = `
<p>أوقات السمر والضحك المستمر هي أوقات تجمع العائلة والأصدقاء والتي يمر فيها الوقت سريعا ويكون كل ما حولنا من أشياء أكثر جمالا مما يبدو عليه، تكون المتعة مضاعفة في حالة ممارسة الألعاب الجماعية التي تقوي العلاقات الاجتماعية بين الناس وتساعدهم في فهم بعضهم البعض.
سطور المقال التالي سوف نوضح لكم فيها الكثير من الألعاب جماعية التي شكلت جزءا من ذكرياتنا اليومية، سوف نستعيد معكم كل لحظة جميلة شعرت بها في الماضي وسوف نوضح لك كيف يمكنك استرجاع هذه الأوقات الشيقة مرة أخرى مع من تحب.
الألعاب الجماعية لم تُصنع لقضاء أوقات الفراغ مع الأشخاص نهاية كل يوم فقط، لكن الألعاب الجماعية تحمل معها الكثير من الذكريات التي عشناها مع أكثر الأشخاص المقربين الي قلوبنا، دائما نتذكر كل لحظة قضيناها في ممارسة تلك الألعاب والتي جعلتنا نرتبط أكثر مع الأصدقاء والعائلة، وهي التي رسخت فينا الحب والتعاون وهي التي كتبت اغلب ذكرياتنا الجميلة.
سوف نوضح لكم من خلال سطور المقال التالي أفكار ألعاب جماعية كثيرة منها المعروف مثل لعبة كرسي الاعتراف – ألعاب الرحلات ومنها الألعاب الحديثة والتي نعتقد أنها ألعاب عبقرية مثل لعبة بروبوقاندا، لذلك استكمال قراءة سطور المقال التالي سوف تجعلك تستعيد كل الذكريات وسوف تجعلك تخلق نوع جديد من المرح سواء أثناء أوقات التجمع العائلي أو مع الأصدقاء.
</p>
<h2>أفكار ألعاب جماعية للرحلات</h2>
<p>الجميع يفضل القيام بالرحلات من حين إلى آخر، حتى يحصل على قسط من الراحة والاستجمام، هناك أفكار ألعاب جماعية للرحلات كثيرة يمكن ممارستها مع الأصدقاء، حيث يمكن ممارسة الألعاب جماعية للكبار مثل كرة القدم، كما يمكن ممارسة الألعاب الترفيهية البسيطة والتي تناسب جميع الأعمار والتي منها ما يلي: -</p>
<h3>كرسي الاعتراف</h3>
<p>إذا كنت تبحث عن ألعاب جماعية كلامية يمكنك ممارسة لعبة كرسي الاعتراف فهي من أكثر الألعاب التشويقية التي يمكن ممارستها في الرحلات، حيث تقوم هذه اللعبة بإضفاء جو ممتع من المرح والسعادة بين الأشخاص كما يمكنها كسر الروتين وتقوية العلاقات بين الناس لأنها تساعد على إخراج بعض المشاعر الصادقة والحقيقية.
أقل عدد يمكنه ممارسة هذه اللعبة هو ثلاثة أشخاص وكلما زاد العدد أصبحت اللعبة أكثر تشويقا، تختلف الأسئلة التي يمكن طرحها أثناء ممارسة هذه اللعبة، حيث يمكن توجيه أسئلة هادفة – جريئة – رياضية – سياسة وغيرها.
يمكن ممارسة لعبة كرسي الاعتراف من خلال تعيين شخص ثابت يطرح أسئلة على كل المشاركين في اللعبة، يتم تثبيت السؤال ويتم طرحه على الجميع لتلقي الإجابات.
كما يمكن ممارسة هذه اللعبة من خلال استقبال وإرسال الأسئلة من المشاركين في اللعبة، من الممكن أن يستخدم زجاجة مياه فارغة وتدويرها وعند توقفها يسأل الشخص الجالس خلف الزجاجة الشخص الجالس أمام الزجاجة السؤال الذي يريده وهكذا.
</p>
<h3> لعبة حقيقتين وكذبة</h3>
<p>تعتبر لعبة حقيقتين وكذبة لعبة كلامية للكبار حيث انها ممتعة ولا تحتاج بذل المزيد من الجهد أثناء ممارستها، تقوم فكرة هذه اللعبة علي توضيح أشياء كثيرة حقيقية حول الأشخاص الذين يقومون بممارستها معا، لذلك تضفي جوا من المرح الكبير وتعمل على تقوية العلاقات الإنسانية بين الناس بشكل كبير، كما يمكنك توضيح بعض الأشياء المهمة حول شخصيتك وما تحب وما تكره بشكل غير مباشر للأصدقاء.
أقل عدد يمكنه ممارسة هذه اللعبة هما شخصان ومن الطبيعي كلما زاد عدد اللاعبين كان أفضل، لكن يمكن أن تكون مثالية بين شخصين فقط لتوضيح الكثير من الأمور الشخصية المتعلقة بهم على شكل لعبة.
تبدأ اللعبة بأن يقول شخص ثلاث عبارات يكون بين هذه العبارات عبارة واحدة فقط خاطئة، ويجب على المشاركين في اللعبة التعرف عن العبارة الخاطئة التي ذكرها هذا الشخص، العبارات التي يقولها المتنافسون تكون عبارة عن حقائق عن صفاتهم الشخصية أو الأشياء التي يحبونها ويكرهونها.
</p>
<h3> سباق الدراجات</h3>
<p>سباق الدراجات يمكن أن يتم تصنيفه على أنه العاب جماعية للشباب حيث يحتاج إلى مزيد من القوة والطاقة للفوز، وهي لعبة رائعة أثناء الرحلات حيث تضفي الكثير من المرح وتثير حماسة جميع الأشخاص بطريقة غير عادية، يا لها من لحظات جميلة تلك التي تُسرع فيها أثناء قيادة الدراجة وترى بجانبك الكثير من الأحبة حيث أن امتزاج شعور المنافسة بالحب والمرح لا يمكن وصفه بكلمات.
أقل عدد يمكنه ممارسة هذه اللعبة هما شخصان، لكن يكون ممارسة هذه اللعبة خرافيا كلما تضاعف الأشخاص المتسابقون.
يمكن استخدام الدرجات الشخصية في حالة توفر هذا، أو تأجير الدراجات من محالّ الدرجات والتي عادت ما تكون موجودة في الأماكن الخاصة بالرحلات، يتم تحديد نقطة لبداية السباق ونقطة لنهايته – تتم الاستعانة بشخص أو شخصين لا يمكنهم قيادة الدراجات لتنظيم السباق وتحديد الفائز يقف واحد منهم عند نقطة البداية والآخر عند نقطة النهاية – يبدأ السباق ويتنافس الجميع للوصول إلى نقطة النهاية قبل الآخر، أول شخص يصل إلى نقطة النهاية هو الفائز يمكن مكافئته ببعض الحلوى أو يختار هو شخص ليحكم عليه بتنفيذ طلب معين.
</p>
<h2>ألعاب مع الأصدقاء في البيت</h2>
<p>قضاء الوقت في المنزل بعد الانتهاء من يوم عمل شاق أو يوم العطلة الأسبوعية من الممكن أن يكون مملا بعض الشيء، لذلك يبحث الجميع عن أفكار ألعاب جماعية في البيت يمكنها القضاء على الملل وإضفاء المزيد من المرح وتقوية العلاقات الأسرية أو علاقات الصداقة، وسوف نوضح لكم بعض الألعاب التي يمكن ممارستها أثناء الجلوس في المنزل فيما يلي: -</p>
<h3> بروبوقاند</h3>
<p>تريد لعبة جماعية يمكن ممارستها مع الأصدقاء عبر الجوال؟ إذا كانت الإجابة نعم، نحن نرشح لك لعبة بروبوقاندا فهي من أفضل الألعاب التي يمكن ممارستها مع الأصدقاء بالجوال، لعبة جديدة ومميزة للغاية يمكنها أن تجعل أوقات الفراغ أكثر مرحا حيث تجعلك تمارس الخداع بشكل قانوني بل ممارسة الخداع سوف يساعدك في الفوز على أصدقائك.
تتمثل فكرة اللعبة في الإجابة على أسئلة بسيطة عبر اختيار الإجابة الصحيحة من بين الخيارات المتاحة، ثم محاولة إقناع الآخرين بأن هذه الإجابة هي الصحيحة، في حين تحاول تضليلهم.
تكمن المتعة في التحدي والمكر، حيث تجد نفسك تقدم إجابات خاطئة وتحاول إقناع الآخرين بأنها الصحيحة، مما يمنحك الفرصة للفوز والتفوق على منافسيك.
للفوز في هذه اللعبة، يجب أن تكون إجابتك قريبة جدًا من الإجابة الصحيحة، وأن تتمتع بمهارات جيدة في الخداع والتلاعب، مما يمكنك من إقناع الآخرين بأن إجابتك هي الصحيحة وبالتالي تحقيق النقاط اللازمة للفوز.
من خلال تجربتنا الشخصية مع هذه اللعبة، وجدنا أن الوقت الذي قضيناه كان مليئًا بالمرح والضحك، حيث كنا نسعى جاهدين لإقناع الآخرين بإجاباتنا ونجحنا في تحقيق النقاط بفضل مهاراتنا في الخداع والتلاعب.
باختصار، لعبة بروبوقاندا تتطلب الذكاء والمكر، ومن خلال استخدام هذه الصفات بشكل جيد يمكنك الفوز على خصومك والتفوق في هذه اللعبة المثيرة.
</p>
<h3> امنع الضحك</h3>
<p>أفكار الألعاب الجماعية للعائلة كثيرة لكن الكل يبحث عن لعبة تناسب جميع الأعمار وتكون ممارستها سهلة، من المؤكد أن لعبة امنع الضحك واحدة من الألعاب الجماعية العائلية التي تناسب الجميع دون استثناء ويمكنها خلق جو من المرح في إطار مهذب وممتع.
يمكن ممارسة هذه اللعبة من قبل ثلاثة أشخاص على الأقل، يفضل ممارستها في أوقات النهار لأنها ألعاب عائلية مضحكة ينتج عنها بعض الضوضاء والتي من الممكن أن تكون مزعجة للغير.
تعتمد اللعبة على محاولة إضحاك الآخرين، حيث يتم الجلوس بشكل معين يتم تحديده من قبل المجموعة، ثم يحاول كل شخص في المجموعة عمل بعض الحركات أو إلقاء النكات حتى يقوم بإضحاك المنافسين، وفي حالة تغيير تعبير وجهك سواء بالضحك أو الابتسام يتم إقصاءك من اللعبة ويعتبر الشخص الأخير هو الفائز، وتحدد جائزة للفائز يمكن الاتفاق عليها من قبل المجموعة.
</p>
<h3> لعاب الورق والقلم
</h3>

<p>هناك الكثير من أفكار ألعاب جماعية للسهرات والمعسكرات، من أهم هذه الألعاب ألعاب الورق والقلم فهي كثيرة للغاية ومتنوعة، تساعد كثيرا علي قضاء وقتا ممتعا مع الآخرين وسوف نوضح لكم فيما يلي بعض من هذه الألعاب: -
لعبة اتوبيس كومبليت
يعتبرها البعض ألعابا جماعية للسهرات لأنها من الألعاب التي عادة ما تمارس في أوقات تجمع العائلة ليلا، أو نهاية اليوم في الرحلات.
أقل عدد يمكنه ممارسة هذه اللعبة هما شخصان، واختلف الكثير حول عدد اللاعبين وتأثيره على روعة هذه اللعبة، لذلك إذا اعتبرناها ألعاب عائلية مضحكة يفضل أن يكون عدد المتنافسين فيها يتجاوز العشرة، أما إذا اعتبرناها ألعاب جماعية للكبار يفضل أن يكون عدد المتنافسين قليل.
يتم اختيار حرف من الحروف الأبجدية، ويقوم المتنافسون بملء جدول تم إعداده مسبقا يحتوي على بعض الأشياء منها: اسم بلد – اسم حيوان – اسم جماد وغيرها ويجب أن تبدأ جميعها بالحرف الذي تم اختياره، يمكن للشخص الفائز تحديد الحرف في كل مرة، ويمكنك أن تكون الفائز في حالة تكوين كل الأشياء المطلوبة في أسرع وقت.
لعبة XO
لعبة سريعة يتنافس فيها شخصان بشكل مباشر، يمكن أن تمارسها مستخدما الورقة والقلم كما يمكنك أن تمارسها على الرمال أثناء قضاء العطلة الصيفية.
يتم رسم مربع كبير يحتوي على تسع خانات داخلية، يختار أحد المتنافسين رمز X أو O ويأخذ الشخص الآخر الرمز المتبقي، تتم كتابة هذه الرموز داخل الخانات الصغيرة وتفوز في حالة كتابة الرمز الذي اخترته ثلاث مرات بشكل متتالي.
</p>
<h2> ألعاب جماعية مع الأصدقاء بالجوال</h2>
<p>تحدثنا فيما سبق عن أفكار ألعاب جماعية للرحلات وعن ألعاب مع الأصدقاء في البيت، ووضحنا كيف أن الألعاب الجماعية يمكنها أن تخلق أوقات مرح للجميع.
وفي هذه الفقرة سوف نوضح لكم ألعاب جماعية مع الأصدقاء بالجوال، وكيف يمكن للتكنولوجيا أن تجعل أوقات الفراغ الخاصة بالناس أفضل وأمتع.
</p>
<h3> بروبوقاندا</h3>
<p>أفضل لعبة جماعية مع الأصدقاء بالجوال هي لعبة بروبوقاندا، في لعبة تشويقية ممتعة تجعلك تستخدم الكثير من الحيل حتى تستطيع خداع الأصدقاء والحصول على النقاط، فهي لعبة حماسية إلى حد كبير حيث تحفز كل حواسك وعقلك على التفكير في وضع خطة يمكنها أن تؤدي إلى فوزك في النهاية.
لعبة بروبوقاندا هي لعبة تحايل وخداع يمكن ممارستها من قبل شخصين إلى ثمانية أشخاص. الفكرة الأساسية للعبة هي طرح الأسئلة على المتنافسين، حيث يقوم كل لاعب بإختيار الإجابة الصحيحة للسؤال.
لكن أين التحايل والخداع الذي ذكرناه؟ ستقوم أنت بكتابة إجابة من ضمن الإجابات التي ستظهر للمتنافسين، حيث يكون دورك هو كتابة أقرب إجابة للإجابة الصحيحة ومحاولة إقناع أصدقائك بأن هذه الإجابة هي الصحيحة، بهدف الحصول على 500 نقطة. كما يجب عليك أنت اختيار الإجابة الصحيحة أيضًا، لتحصل على 1000 نقطة.
أفضل عدد يمكنه ممارسة هذه اللعبة في وقت واحد هو خمسة أشخاص، حتى يكون الأمر أكثر تشويقا وفاعلية.
</p>
<h3> لعبة Monopoly</h3>
<p>اشهر لعبة جماعية مع الأصدقاء في البيت هي لعبة Monopoly وهي من أكثر الألعاب التي يمكنها مساعدة الصغار والكبار على معرفة أسس التجارة وكيفية تكوين ثروات كبيرة وضخمة، وكيف يمكن أن تضع خطة استراتيجية تستطيع من خلالها الحصول على الكثير من الأموال في وقت قصير.
يمكن ممارسة اللعبة من قبل شخص إلى ثمان اشخاص على الأكثر، وهي ممتعة في كلتا الحالتين.
فكرة اللعبة مبنية على شراء البلاد الموجودة على ظهر اللعبة، وتكوين تكتلات من هذه المدن حتى يتم جمع المال الوفير في حالة مرور الأصدقاء على هذه المدن التي تمتلكها، تحصل علي قطعة بلاستيكية تأتي بأشكال متعددة تعبر عن وجودك باللعبة ويتم تحريكها بعد إلقاء قطعة النرد علي حسب الرقم الذي ظهر لك، تستطيع شراء المدن التي تقف عليها في حالة امتلاك المال اللازم لشرائها، وسوف تصبح الفائز في حالة إشهار إفلاس جميع المنافسين.
</p>
<h3>لعبة UNO</h3>
<p>يمكن تصنيفها من ضمن ألعاب جماعية للمعسكرات أو الرحلات أو السهرات، لعبة قديمة للغاية لكنها ممتعة إلى حد كبير.
يفضل ممارسة هذه اللعبة من قبل خمسة أشخاص على الأقل، لكن يمكن أن يقل العدد لكن حتى تشعر بأعلى درجات السعادة يجب أن يكون عدد المنافسين كثيرا.
فكرة اللعبة هي أن تنهي الأوراق التي وزعت عليك في أول اللعبة أسرع من أي شخص آخر، يتم وضع رقم في منتصف الطاولة ويوزع على كل شخص سبع ورقات، يمكنك وضع إحدى الأوراق التي تمتلكها على الورقة التي وضعت في منتصف الطاولة على حسب الرقم أو اللون، والذي يضع كل الأوراق التي في حوزته أولا يصبح هو الفائز.
</p>
<h2>ألعاب عائلية مضحكة</h2>
<p>سوف نوضح لكم مجموعة من الألعاب العائلية المضحكة، يمكن ممارستها مع أفراد العائلة أو الأصدقاء المقربين، سوف تخرجك من حالة الملل في بضع دقائق وكذلك سوف تملأ وقت فراغك بالكثير من المرح والمتعة.</p>
<h3> لعبة البلوت</h3>
<p>يعتقد البعض أن أصعب الالعاب جماعية هي لعبة البلوت وهذا في الحقيقة ليس صحيح بل العكس فهي لعبة سهلة للغاية ويمكنها أن تجعل جميع أفراد العائلة في حالة من السعادة والضحك.
يجب أن يتواجد أربعة أشخاص لممارسة هذه اللعبة، يتم توزيع 8 ورقات من الكوتشينة على كل لاعب، كل شخصين متقابلين يكونوا فريقا واحدا، يقوم أول شخص بإلقاء أكبر رقم يمتلكه من الأوراق، ويلتزم الآخرون بوضع ورقة من نفس الشكل، وفي حالة عدم امتلاك نفس الشكل يمكنك اختيار ورقة من شكل آخر ويتم اعتبرها أقل ورقة من حيث القوة.
قوة الأوراق ترتب على النحو التالي: رقم واحد – رقم عشرة – الملك (الشايب) – الملكة (البنت) – الرجل (الولد) – رقم 9 – رقم 8 – رقم 7.
</p>
<h3> لعبة الكيرم</h3>
<p>إذا كنت تبحث عن أفكار ألعاب جماعية مختلفة لن تجد أفضل من لعبة الكيرم، حيث تقوي هذه اللعبة أجواء التنافس كما يمكنها تنمية مهارات التركيز بشكل كبير.
يستطيع أربعة أشخاص ممارسة هذه اللعبة وهي تشبه إلى حد كبير لعبة البلياردو من حيث إنها تحتاج إلى التصويب على الأحجار وتنجح فقط في حالة إدخال هذه الأحجار داخل الفتحات الموجودة على جوانب طاولة اللعب، يتم تحديد الفائز في النهاية علي حسب الأرقام التي حصل عليها من إنزال الأحجار في الفتحات وأيضا يجب أن يكون الفائز أول من ينهي جميع الأحجار الخاصة به.
</p>
<h3> لعبة الكراسي</h3>
<p>أشهر لعبة جماعية للعائلة تهدف إلى تنمية مهارات التدقيق والمرونة عند المتنافسين، كما تطلب المزيد من السرعة لضمان الفوز بجميع جولتها.
عدد اللاعبين متفاوت لكن لا يقل عن ثلاثة متنافسين، يتم وضع عدد من كراسي الجلوس على أن يكون أقل من عدد المتنافسين بمقدار كرسي واحد، تبدأ اللعبة بالغناء سواء من خلال أجهزة الكاسيت أو من خلال أحد الأفراد المتواجدين، أثناء الغناء يجب على المتنافسين الدوران حول الكراسي التي تم وضعها، وعند توقف الغناء يجب الإسراع من الجميع للحصول على مقعد للجلوس عليه، يتبقى الفرد الأخير الذي لن يجد مكان له ويظل الأمر هكذا بإنقاص كرسي في كل جولة حتى يصبح هناك شخص واحد استطاع الجلوس على كرسي في جميع الجولات.
</p>
<h2>أفكار ألعاب جماعية للشباب</h2>
<p>أصبح نادر جدا الحصول على لعبة جماعية للشباب يمكن ممارستها في أوقات الفراغ والرحلات، وسوف نساعدكم في هذا الأمر بتوضيح أفضل الألعاب في الوقت الحالي التي يمكن ممارستها فيما يلي: -</p>
<h3> حقيقتان وكذبة</h3>
<p>تعتبر لعبة حقيقتين وكذبة أفضل لعبة جماعية للشباب حيث يمكن ممارستها في العطلات والإجازات وهي تقوي الروابط الاجتماعية بين الناس لأنها تساعد في الكشف عما بداخل الأشخاص وطرحه للجميع بشكل فكاهي ممتع.
يمكن لشخصين فقط ممارسة هذه اللعبة، فكرة اللعبة هي أن تقوم بقول ثلاث عبارات عن حياتك الشخصية أو الأشياء التي تحب القيام بها، على أن تكون عبارة واحدة من ضمن هذه العبارات خاطئة وتحاول فيها تضليل المنافسين، ويجب على المنافسين توضيح العبارة الخاطئة وفي حالة عدم تمكنهم من ذلك تصبح أنت الفائز.
</p>
<h3> قل لي سر</h3>
<p>هناك بعض الأشخاص يبحثون عن لعبة جماعية بالكلام لا يبذل فيها أي مجهود كل ما تحتاج إليه هو التحدث فقط والاستمتاع، وسوف نوضح لكم اشهر لعبة في هذا النوع من الألعاب وهي لعبة قل لي سر، والتي تخبرك الكثير عن أصدقائك أو أفراد عائلتك بشكل فكاهي ومرح.
يمكن ممارسة هذه اللعبة من قبل شخصين فقط، لكن كلما زاد العدد كان أكثر حتى تنزيل الإحراج أثناء التحدث.
يتم طرح بعض الأسئلة من شخص لا يقوم باللعب ودوره هو توجيه الأسئلة على المتنافسين، تكون الأسئلة على سبيل المثال: ما هي ذكريات الطفولة المحببة إلى قلبك؟ كمْ مرة كذبت على والديك وهربت من المدرسة؟ علي هذا النحو يتم توجيه الأسئلة حتى تستطيع إخراج الأشياء التي لا يذكرها الناس بعضهم لبعض في الحالات العادية.
ختاما وبعد تجربة أغلب الألعاب التي ذكرناها لكم، يجب القول إن لعبة بروبوقاندا هي أفضل وأمتع لعبة من بين الجميع، هذا ليس رأي الشخصي بل رأي جميع أصدقائي الذين شاركوني هذه اللعبة العبقرية، ونحن ننصحكم بتجربة لعبة بروبوقاندا التي تقدمها شركة المضحكين المحدودة ولن تندموا أبدا بل العكس سوف تحصلون على أوقات ممتعة مع الأصدقاء أثناء ممارسة هذه اللعبة.
</p>
`;
